@import 'src/utils/utils';

.banner {
  @include zero;
  position: relative;
  padding: 16px;
  background-color: $Main-2;
  margin-bottom: 20px;

  @media(min-width: $md) {
    margin-right: 16px;
    margin-left: 16px;
    border-radius: 20px;
  }
  @media(min-width: $lg) {
    margin-right: 0;
    margin-left: 0;
  }
  @media(min-width: $xxl) {
    @include box;
    padding: 40px;
    padding-top: 35px;
  }

  &__meta {
    @include zero;
    display: grid;
    padding-right: 30px;
    row-gap: 10px;

    @media(min-width: $lg) {
      display: flex;
      align-items: center;
      column-gap: 15px;
      margin-bottom: 10px;
    }
  }

  &__category {
    @include zero;
    display: flex;
    column-gap: 10px;

    svg {
      min-width: 24px;
      width: 24px;
      height: 24px;
    }

    span {
      @include Text-14-bold;
      color: $Main-1;
    }
  }

  &__label {
    @include zero;
    @include Text-14-reg;
    padding-top: 3px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    border-radius: 8px;
    color: $Main-1;
    background-color: $t-40;
    justify-self: start;

    @media(min-width: $xxl) {
      @include Text-14-bold;
      padding-top: 5px;
      padding-right: 15px;
      padding-bottom: 5px;
      padding-left: 15px;
    }

    &--green {
      background-color: $Alternative-4;
      color: $Main-2;
    }

    &--brown {
      background-color: $Alternative-10;
      color: $Main-1;
    }

    &--gray {
      background-color: transparent;
      color: $t-60;
      border: 1px solid $t-60;
    }
  }

  &__date {
    @include zero;
    @include Text-14-reg;
    color: $t-60;
  }

  &__more {
    @include zero;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    border: 0;
    background-color: transparent;
    cursor: pointer;

    @media(min-width: $xxl) {
      top: 24px;
      right: 20px;
    }

    &:hover {
      @media(min-width: $lg) {

        svg {
          fill: $Corporate-2;
        }
      }
    }

    svg {
      transition: 0.3s;
      fill: $Main-1;
    }
  }

  &__header {
    @include zero;
    @include Text-16-bold;
    color: $Main-1;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none;
    transition: color 0.3s;

    @media(min-width: $xxl) {
      @include Text-18-bold;
    }

    &[href]:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
      }
    }
  }

  &__footer {
    @include zero;
    display: grid;
    margin-top: 10px;
    justify-content: start;
    gap: 10px;

    @media(min-width: $lg) {
      display: flex;
      align-items: center;
      margin-top: 20px;
      column-gap: 15px;
      gap: 15px;
    }
  }

  &__type {
    @include zero;
    display: flex;
    column-gap: 5px;
    align-items: center;

    svg {
      width: 18px;
      height: 18px;
    }

    span {
      @include Text-12-reg;
      color: $Main-1;
    }
  }

  &__subscription {
    @include zero;
    display: flex;
    column-gap: 5px;
    align-items: center;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    border-radius: 8px;
    border: 1px solid $Main-1;
    justify-self: flex-start;

    @media(min-width: $xxl) {
      padding-right: 15px;
      padding-left: 15px;
    }

    svg {
      min-width: 14px;
      width: 14px;
      height: 14px;
    }

    p {
      @include zero;
      @include Text-12-reg;
    }

    &--orange {
      color: $Alternative-1;
      border-color: $Alternative-1;
    }

    &--green {
      color: $Alternative-4;
      border-color: $Alternative-4;
    }
  }


  &__views {
    @include zero;
    @include Text-12-reg;
    display: flex;
    align-items: center;
    column-gap: 5px;
    justify-self: start;

    @media(min-width: $lg) {
      margin-left: auto;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__hashtags {
    @include zero;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 5px;
    row-gap: 5px;
    margin-top: 10px;

    @media(min-width: $lg) {
      margin-top: 20px;
    }
  }

  &__hashtag {
    @include zero;
    @include Text-14-reg;
    color: $Main-6;
    text-decoration: none;
    transition: color 0.3s;
    cursor: pointer;

    &:hover {
      @media(min-width: $lg) {
        color: $Main-1;
      }
    }
  }

  &__subscribe {
    @include zero;
    padding: 20px;
    border-radius: 20px;
    background-color: #E7F6EB;
    margin-top: 15px;

    @media(min-width: $xl) {
      display: flex;
      column-gap: 52px;
      padding-top: 20px;
      padding-right: 35px;
      padding-left: 40px;
      padding-bottom: 32px;
    }

    img {
      display: none;
      transform: translateY(32px);
      max-width: 340px;
      width: 100%;

      @media(min-width: $xl) {
        display: block;
      }
    }
  }

  &__content {
    @include zero;
    display: grid;
    row-gap: 15px;
    align-items: start;

    @media(min-width: $xl) {
      row-gap: 25px;
    }
  }

  &__sub-header {
    @include zero;
    @include Head-22-bold;
    color: $Main-1;

    @media(min-width: $lg) {
      @include Head-35-bold;
    }
  }

  &__button {
    @include zero;
    display: flex;
    column-gap: 5px;
    padding: 15px;
    border: 0;
    background-color: $Alternative-4;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
    text-decoration: none;

    @media(min-width: $xl) {
      max-width: 303px;
      padding-top: 15px;
      padding-right: 25px;
      padding-bottom: 15px;
      padding-left: 25px;
      column-gap: 10px;
    }

    &:hover {
      @media(min-width: $lg) {
        background-color: $Corporate-1;
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }

    span {
      @include Text-14-reg;
      color: $Main-2;

      @media(min-width: $xl) {
        @include Text-16-bold;
      }
    }
  }
}